import { WINDOW_GLOBAL } from "../constants";
import { AnalyticsConfig } from "../types/analytics";
import { PlayerSdkRender } from "../types/common";

interface WindowGlobals {
  currentTime?: number;
  programId?: string;
  render?: PlayerSdkRender;
  analyticsConfig?: Promise<AnalyticsConfig>;
}

export const setWindowProperties = (properties: WindowGlobals) => {
  if (!window[WINDOW_GLOBAL]) {
    window[WINDOW_GLOBAL] = {};
  }

  window[WINDOW_GLOBAL] = { ...window[WINDOW_GLOBAL], ...properties };
};
